export const PLATFORM_OPTIONS = Object.freeze([
	{ id: 'mobile', title: 'Mobile' },
	{ id: 'tablet', title: 'Tablet' },
	{ id: 'desktop', title: 'Desktop' },
]);

export const OS_OPTIONS = Object.freeze([
	{ id: 'windows', title: 'Windows' },
	{ id: 'macos', title: 'MacOS' },
	{ id: 'android', title: 'Android' },
	{ id: 'ios', title: 'iOS' },
]);

export const ISP_OPTIONS = Object.freeze([
	{ id: 'DIALUP', title: 'Dialup' },
	{ id: 'CABLE/DSL', title: 'Cable/DSL' },
	{ id: 'CELLULAR', title: 'Cellular' },
]);

export const SERVER_URL = process.env.VUE_APP_SERVER_URL;
export const COOKIE_DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN;
export const REF_QUERY_KEY = process.env.VUE_APP_REF_QUERY_KEY || 'ref';
export const REF_QUERY_SUBID1_KEY = process.env.VUE_APP_REF_QUERY_SUBID1_KEY || 'ref_subid1';
export const REF_QUERY_SUBID2_KEY = process.env.VUE_APP_REF_QUERY_SUBID2_KEY || 'ref_subid2';
export const AD_WATCHER_SCREENSHOT_URL = 'https://gw.1push.io/';
export const TERMS_URL = 'https://rollerads.com/terms.html';

export const GIT_BRANCH = process.env.VUE_APP_GIT_BRANCH || '';
export const IS_NEW_API = process.env.VUE_APP_NEW_API === 'true';

export const FORMAT_IDS = {
	PUSH_FORMAT_ID: 1,
	ON_CLICK_FORMAT_ID: 2,
	IN_PAGE_PUSH_FORMAT_ID: 3,
	NATIVE_FORMAT_ID: 4,
	CALENDAR_FORMAT_ID: 5,
	INTERSTITIAL_FORMAT_ID: 6,
	DIRECT_CLICK_FORMAT_ID: 7,
};

export const BID_MODEL_IDS = {
	CPC: 1,
	CPM: 2,
	CPA: 3,
	PARSED: 4,
	SMART_CPC: 5,
	SMART_CPM: 6,
	CPA_GOAL_CPC: 7,
	CPA_GOAL_CPM: 8,
	CPI: 9,
};

export const FORMATS_OPTIONS = [
	{ id: FORMAT_IDS.PUSH_FORMAT_ID, title: 'Push' },
	{ id: FORMAT_IDS.ON_CLICK_FORMAT_ID, title: 'OnClick' },
	{ id: FORMAT_IDS.IN_PAGE_PUSH_FORMAT_ID, title: 'In-Page Push' },
	{ id: FORMAT_IDS.NATIVE_FORMAT_ID, title: 'Native' },
	{ id: FORMAT_IDS.CALENDAR_FORMAT_ID, title: 'Calendar' },
	{ id: FORMAT_IDS.INTERSTITIAL_FORMAT_ID, title: 'Interstitial' },
	{ id: FORMAT_IDS.DIRECT_CLICK_FORMAT_ID, title: 'Direct click' },
];

export const FORMAT_COST_MODEL_ID_OPTIONS = {
	[FORMAT_IDS.PUSH_FORMAT_ID]: [
		{ id: 1, title: 'RevShare' },
		{ id: 2, title: 'FixCPC' },
		{ id: 3, title: 'FixCPM' },
	],
	[FORMAT_IDS.ON_CLICK_FORMAT_ID]: [
		{ id: 1, title: 'RevShare' },
		{ id: 3, title: 'FixCPM' },
	],
	[FORMAT_IDS.IN_PAGE_PUSH_FORMAT_ID]: [
		{ id: 1, title: 'RevShare' },
		{ id: 2, title: 'FixCPC' },
		{ id: 3, title: 'FixCPM' },
	],
	[FORMAT_IDS.NATIVE_FORMAT_ID]: [
		{ id: 1, title: 'RevShare' },
		{ id: 2, title: 'FixCPC' },
		{ id: 3, title: 'FixCPM' },
	],
	[FORMAT_IDS.CALENDAR_FORMAT_ID]: [
		{ id: 1, title: 'RevShare' },
		{ id: 2, title: 'FixCPC' },
		{ id: 3, title: 'FixCPM' },
	],
	[FORMAT_IDS.INTERSTITIAL_FORMAT_ID]: [
		{ id: 1, title: 'RevShare' },
		{ id: 2, title: 'FixCPC' },
		{ id: 3, title: 'FixCPM' },
	],
	[FORMAT_IDS.DIRECT_CLICK_FORMAT_ID]: [
		{ id: 1, title: 'RevShare' },
		{ id: 3, title: 'FixCPM' },
	],
};

export const FORMATS = {
	[FORMAT_IDS.PUSH_FORMAT_ID]: {
		key: 'push',
		id: FORMAT_IDS.PUSH_FORMAT_ID,
		title: 'Push',
		costModelIdOptions: FORMAT_COST_MODEL_ID_OPTIONS[FORMAT_IDS.PUSH_FORMAT_ID],
	},
	[FORMAT_IDS.ON_CLICK_FORMAT_ID]: {
		key: 'on_click',
		id: FORMAT_IDS.ON_CLICK_FORMAT_ID,
		title: 'OnClick',
		costModelIdOptions: FORMAT_COST_MODEL_ID_OPTIONS[FORMAT_IDS.ON_CLICK_FORMAT_ID],
	},
	[FORMAT_IDS.IN_PAGE_PUSH_FORMAT_ID]: {
		key: 'in_page_push',
		id: FORMAT_IDS.IN_PAGE_PUSH_FORMAT_ID,
		title: 'In-Page Push',
		costModelIdOptions: FORMAT_COST_MODEL_ID_OPTIONS[FORMAT_IDS.IN_PAGE_PUSH_FORMAT_ID],
	},
	[FORMAT_IDS.NATIVE_FORMAT_ID]: {
		key: 'native',
		id: FORMAT_IDS.NATIVE_FORMAT_ID,
		title: 'Native',
		costModelIdOptions: FORMAT_COST_MODEL_ID_OPTIONS[FORMAT_IDS.NATIVE_FORMAT_ID],
	},
	[FORMAT_IDS.CALENDAR_FORMAT_ID]: {
		key: 'calendar',
		id: FORMAT_IDS.CALENDAR_FORMAT_ID,
		title: 'Calendar',
		costModelIdOptions: FORMAT_COST_MODEL_ID_OPTIONS[FORMAT_IDS.CALENDAR_FORMAT_ID],
	},
	[FORMAT_IDS.INTERSTITIAL_FORMAT_ID]: {
		key: 'interstitial',
		id: FORMAT_IDS.INTERSTITIAL_FORMAT_ID,
		title: 'Interstitial',
		costModelIdOptions: FORMAT_COST_MODEL_ID_OPTIONS[FORMAT_IDS.INTERSTITIAL_FORMAT_ID],
	},
	[FORMAT_IDS.DIRECT_CLICK_FORMAT_ID]: {
		key: 'direct_click',
		id: FORMAT_IDS.DIRECT_CLICK_FORMAT_ID,
		title: 'Direct click',
		costModelIdOptions: FORMAT_COST_MODEL_ID_OPTIONS[FORMAT_IDS.DIRECT_CLICK_FORMAT_ID],
	},
};

export const RATE_FIELDS = {
	cost_value: undefined,
	cost_model_id: undefined,
};

export const MESSAGE_SEND_TYPES = ['now', 'specific_time', 'schedule'];

export const MESSAGE_SCHEDULE_HOURS = [
	'00:00 - 01:00',
	'01:00 - 02:00',
	'02:00 - 03:00',
	'03:00 - 04:00',
	'04:00 - 05:00',
	'05:00 - 06:00',
	'06:00 - 07:00',
	'07:00 - 08:00',
	'08:00 - 09:00',
	'09:00 - 10:00',
	'10:00 - 11:00',
	'11:00 - 12:00',
	'12:00 - 13:00',
	'13:00 - 14:00',
	'14:00 - 15:00',
	'15:00 - 16:00',
	'16:00 - 17:00',
	'17:00 - 18:00',
	'18:00 - 19:00',
	'19:00 - 20:00',
	'20:00 - 21:00',
	'21:00 - 22:00',
	'22:00 - 23:00',
	'23:00 - 24:00',
];

export const MESSAGE_SCHEDULE_DAYS_TYPE = ['every_day', 'days_week', 'days_month'];

export const MESSAGE_SCHEDULE_DAYS_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MESSAGE_SCHEDULE_DAYS_MONTH = [
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'28',
	'29',
	'30',
	'31',
];

export const osVersionToOs = {
	android_15: 'android',
	android_14: 'android',
	android_13: 'android',
	android_12: 'android',
	android_11: 'android',
	android_10: 'android',
	android_9: 'android',
	android_8: 'android',
	android_7: 'android',
	android_6: 'android',
	android_5: 'android',
	android_4: 'android',
	android_3: 'android',
	android_2: 'android',

	win_10: 'windows',
	win_8: 'windows',
	win_7: 'windows',
	win_vista: 'windows',
	win_xp: 'windows',
	win_2000: 'windows',

	ios_18: 'ios',
	ios_17: 'ios',
	ios_16: 'ios',
	ios_15: 'ios',
	ios_14: 'ios',
	ios_13: 'ios',
	ios_12: 'ios',
	ios_11: 'ios',
	ios_10: 'ios',
	ios_9: 'ios',
	ios_8: 'ios',
	ios_7: 'ios',
	ios_6: 'ios',
	ios_5: 'ios',
	ios_4: 'ios',
	ios_3: 'ios',

	osx_sequoia: 'macos',
	osx_sonoma: 'macos',
	osx_ventura: 'macos',
	osx_monterey: 'macos',
	osx_big_sur: 'macos',
	osx_catalina: 'macos',
	osx_mojave: 'macos',
	osx_high_sierra: 'macos',
	osx_sierra: 'macos',
	osx_el_capitan: 'macos',
	osx_yosemite: 'macos',
	osx_mavericks: 'macos',
	osx_mountain_lion: 'macos',
};

export const ACCOUNT_INCOME_INFO_ROWS_DEFAULT = [
	{ name: 'ID', key: 'id', val: '' },
	{ name: 'Title', key: 'title', val: '' },
	{ name: 'Contact name', key: 'contactName', val: '' },
	{ name: 'Terms', key: 'terms', val: '' },
	{ name: 'Stat', key: 'stat', val: '' },
];
export const INVOICE_OFFSET_MONTH = 3;
export const POSTPAY_TYPE = 'postpay';
export const PREPAY_TYPE = 'prepay';
export const STATISTICS_OPTIONS = [
	{ id: 'our', title: 'Ours' },
	{ id: 'their', title: 'Their' },
	{ id: 'other', title: 'Other' },
];
export const INVOICE_TYPE_OPTIONS = [
	{ id: 'wire', label: 'Wire' },
	{ id: 'capitalist', label: 'Capitalist' },
	{ id: 'paxum', label: 'Paxum' },
	{ id: 'paypal', label: 'Paypal' },
	{ id: 'payoneer', label: 'Payoneer' },
];

export const ROLLER_ADS_ACCOUNT_ID = 1;

export const DEFAULT_ZONE = 635167;
export const DEFAULT_ZONE_NOTIFICATION = `${DEFAULT_ZONE} is a special technical zone. It can’t be whitelisted or blacklisted. Custom bid can’t be applied for it.`;

export const ROUTES_WITH_FILTERS = ['/adm/statistics', '/adm/pretargeting'];
